import React, { useState } from 'react';
import Select from 'react-select';
import emailjs from 'emailjs-com';
import './TalentProfileForm.css';

function TalentProfileForm({ tags = [], categories = [], countryData = {} }) {
  const [formData, setFormData] = useState({
    profileName: '',
    firstName: '',
    lastName: '',
    talentReview: '',
    category: [],
    tags: [],
    country: '',
    city: '',
    address: '',
    profileImage: null,
    bannerImage1: null,
    bannerImage2: null,
    bannerImage3: null,
    videoUrl: '',
    audioDemo1: null,
    audioDemo2: null,
    audioDemo3: null,
    phoneCode: '',
    phone: '',
    email: '',
    website: '',
    youtube: '',
    instagram: '',
    facebook: '',
    tiktok: '',
    agreePrivacyPolicy: false,
    agreeTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleCategoryChange = (selectedOptions) => {
    setFormData({
      ...formData,
      category: selectedOptions.map(option => option.value),
    });
  };

  const handleTagsChange = (selectedOptions) => {
    setFormData({
      ...formData,
      tags: selectedOptions.map(option => option.value),
    });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption ? selectedOption.value : '',
      city: '',
      phoneCode: selectedOption ? selectedOption.code : ''
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convierte los datos del formulario en un objeto para enviar con EmailJS
    const emailData = {
      profileName: formData.profileName,
      firstName: formData.firstName,
      lastName: formData.lastName,
      talentReview: formData.talentReview,
      category: formData.category.join(', '),
      tags: formData.tags.join(', '),
      country: formData.country,
      city: formData.city,
      phone: formData.phone,
      email: formData.email,
      website: formData.website,
      youtube: formData.youtube,
      instagram: formData.instagram,
      facebook: formData.facebook,
      tiktok: formData.tiktok,
    };

    // Enviar los datos usando EmailJS
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', emailData, 'YOUR_USER_ID')
      .then((response) => {
        console.log('Success:', response.status, response.text);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#333333',
      borderColor: state.isFocused ? '##F21B6A' : '#555555',
      color: '##F21B6A',
      boxShadow: state.isFocused ? '0 0 0 1px #F21B6A' : null,
      '&:hover': {
        borderColor: '#F21B6A',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333333',
      color: '#ffffff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F21B6A' : state.isFocused ? '#444444' : '#333333',
      color: state.isSelected ? '#ffffff' : '#ffffff',
      '&:hover': {
        backgroundColor: '##F21B6A',
        color: '#ffffff',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#F21B6A',
      color: '#ffffff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#F21B6A',
        color: '#ffffff',
      },
    }),
  };

  const categoryOptions = categories.map(cat => ({ label: cat, value: cat }));
  const tagOptions = tags.map(tag => ({ label: tag, value: tag }));
  const countryOptions = Object.keys(countryData).map(country => ({
    label: country,
    value: country,
    code: countryData[country].code,
  }));
  const cityOptions = formData.country ? countryData[formData.country]?.cities.map(city => ({
    label: city,
    value: city,
  })) : [];

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <h7>Datos Personales</h7>
      <div>
        <label>Nombre de perfil:</label>
        <input
          type="text"
          name="profileName"
          value={formData.profileName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Nombres:</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Apellidos:</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Reseña del Talento:</label>
        <textarea
          name="talentReview"
          value={formData.talentReview}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Especialidad:</label>
        <Select
          isMulti
          options={categoryOptions}
          onChange={handleCategoryChange}
          value={categoryOptions.filter(option => formData.category.includes(option.value))}
          styles={customStyles}
        />
      </div>
      <div>
        <label>Generos:</label>
        <Select
          isMulti
          options={tagOptions}
          onChange={handleTagsChange}
          value={tagOptions.filter(option => formData.tags.includes(option.value))}
          styles={customStyles}
        />
      </div>
      <div>
        <label>País:</label>
        <Select
          options={countryOptions}
          onChange={handleCountryChange}
          value={countryOptions.find(option => option.value === formData.country)}
          styles={customStyles}
        />
      </div>
      <div>
        <label>Ciudad:</label>
        <Select
          options={cityOptions}
          onChange={(selectedOption) =>
            setFormData({ ...formData, city: selectedOption ? selectedOption.value : '' })
          }
          isDisabled={!formData.country}
          value={cityOptions.find(option => option.value === formData.city)}
          styles={customStyles}
        />
      </div>

      <h7>Contenido Multimedia</h7>
      <div>
        <label>Imágenes de Perfil:</label>
        <input
          type="file"
          name="profileImage"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Imágenes para banner 1 (PNG):</label>
        <input
          type="file"
          name="bannerImage1"
          accept="image/png"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Imágenes para banner 2 (PNG):</label>
        <input
          type="file"
          name="bannerImage2"
          accept="image/png"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Imágenes para banner 3 (PNG):</label>
        <input
          type="file"
          name="bannerImage3"
          accept="image/png"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>URL de video de presentación (YouTube):</label>
        <input
          type="url"
          name="videoUrl"
          value={formData.videoUrl}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Audio Demo 1 (MP3):</label>
        <input
          type="file"
          name="audioDemo1"
          accept="audio/mpeg"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Audio Demo 2 (MP3):</label>
        <input
          type="file"
          name="audioDemo2"
          accept="audio/mpeg"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <label>Audio Demo 3 (MP3):</label>
        <input
          type="file"
          name="audioDemo3"
          accept="audio/mpeg"
          onChange={handleFileChange}
        />
      </div>

      <h2>Información de Contacto</h2>
      <div>
        <label>Teléfono:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Website:</label>
        <input
          type="url"
          name="website"
          value={formData.website}
          onChange={handleChange}
        />
      </div>

      <h2>Redes Sociales</h2>
      <div>
        <label>YouTube:</label>
        <input
          type="url"
          name="youtube"
          value={formData.youtube}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Instagram:</label>
        <input
          type="url"
          name="instagram"
          value={formData.instagram}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Facebook:</label>
        <input
          type="url"
          name="facebook"
          value={formData.facebook}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>TIKTOK:</label>
        <input
          type="url"
          name="tiktok"
          value={formData.tiktok}
          onChange={handleChange}
        />
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            name="agreePrivacyPolicy"
            checked={formData.agreePrivacyPolicy}
            onChange={handleChange}
            required
          />
          Estoy de acuerdo con la Privacy & Policy
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="agreeTerms"
            checked={formData.agreeTerms}
            onChange={handleChange}
            required
          />
          Estoy de acuerdo con los terms & conditions
        </label>
      </div>

      <button type="submit">Enviar</button>
    </form>
  );
}

export default TalentProfileForm;
