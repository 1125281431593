
const artists = [
  {
    name: "Sam Rincón",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pic-me-at-usted-senalememlo-3.jpeg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pic-me-at-usted-senalememlo-3.jpeg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pic-me-at-usted-senalememlo-3.jpeg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pic-me-at-usted-senalememlo-3.jpeg",
    location: "Perú",
    city: "Lima",
    url: "https://www.youtube.com/watch?v=qVIhjePpQu0",
    artistInfo: {
      bio:"Potencia Tu Sonido Como productor de audio e ingeniero de sonido con una pasión incansable ...",
      bio2: "¡Potencia Tu Sonido! Como productor de audio e ingeniero de sonido con una pasión incansable por la música y más de 15 años de experiencia en la industria, estoy listo para llevar tus proyectos musicales al siguiente nivel. Mi enfoque se centra en la postproducción, donde la magia realmente sucede Lo que puedo ofrecerte Mejora de Calidad Transformaré tus grabaciones en sonidos cristalinos, pulidos y envolventes. Cada nota y matiz brillará con claridad y profundidad. Mezcla Maestra A través de una mezcla experta, uniré cada elemento de tu música de manera equilibrada, logrando un sonido armonioso y cautivador que destaque en cualquier plataforma.Masterización Profesional Daré el toque final a tus pistas para asegurarnos de que estén listas para la distribución, con un volumen óptimo y una calidad de sonido que se destaque en cualquier entorno. Producción Creativa: Si buscas desarrollar una visión única para tu música, trabajaré contigo para dar vida a tus ideas y hacer que tu sonido sea distintivo y memorable. Mi objetivo es colaborar estrechamente contigo para entender tus necesidades y objetivos musicales, y luego superar tus expectativas. Ya sea que estés creando música electrónica, rock, pop, hip-hop, reggae o cualquier otro género, estoy aquí para brindarte una experiencia de producción que elevará tu música a nuevas alturas.¡Hablemos y comencemos a dar vida a tu música!",
      socialMedia: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/_fryde/",
        email: "samrincorr@gmail.com",
        Telephone: "+51-936114574",
        Directions: "narciso de la colina 941, Perú"
      }
    },
    playlist: [
      {
        title: "pdmsong",
        artist: "Sam Rincón",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-stas-knop-1228497-1-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/PDM-separadores-ver3.mp3"
      },
      {
        title: "DavidTyo_LongWayHome",
        artist: "Sam Rincón",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-stas-knop-1228497-1-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/DavidTyo_LongWayHome-MUESTRA-MASTERING-1.mp3"
      },
      {
        title: "DHZ-MIX-frankie-SALSA",
        artist: "Sam Rincón",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-stas-knop-1228497-1-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/DHZ-MIX-frankie-SALSA.mp3"
      },
      {
        title: "LA BRUJULA",
        artist: "Sam Rincón",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-stas-knop-1228497-1-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/LEANDER-REGGAE-LA-BRUJULA-1-1.mp3"
      }

    ],
    categories: ['Productor Musical','Mezcla','Mastering','Grabacion', ],
    tags: ['Country','Fusion','House','Reggae','Pop','Hip-hop','Electronica-dance',],
    coordinates: { lat: 40.7128, lng: -74.0060 }
  },
  {
    name: "Hecs",
    image: "https://panel.productordemusica.com/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-28-at-3.21.08-PM.jpeg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-28-at-3.21.08-PM-3.jpeg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-17-at-3.01.17-PM.jpeg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2024/06/WhatsApp-Image-2024-05-10-at-5.33.49-PM-scaled.jpeg",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=8NTPP6nweuY",
    city: "Lima",
    artistInfo: {
      bio: "Hecs es un músico urbano alternativo con varios álbumes lanzados...",
      bio2: "Hecs es un músico urbano alternativo con varios álbumes lanzados...",
      socialMedia: {
        facebook: "https://facebook.com/artist2",
        twitter: "https://twitter.com/artist2",
        instagram: "http://www.instagram.com/hecs.__",
        email: "hunityartist@gmail.com",
        Telephone: "+51926011429",
        Directions: "Calle Porta 130, Reducto, Miraflores, Lima, Lima Metropolitana, Lima, 15074, Perú"
      }
    },
    playlist: [
      {
        title: "Puesto pal Problema",
        artist: "Hecs",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/07/Ator-Untela-Ft-Pacho-Alqaeda-Puesto-pal-problema-HecsProd.mp3"
      },
      {
        title: "Neo-Reeves-KM2",
        artist: "Hecs",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/07/Neo-Reeves-KM2-HecsProd.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Abuse",
        artist: "Hecs",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/07/Loudnezz-Abuse-HecsProd.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
    ],
    categories: ['Productor Musical','Beatmaker','Cantante','Compositor',],
    tags: ['Drill', 'Latin', 'Rkt','Hip-hop','Regueton','Trap'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "MONTESANO",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/11/montesano-scaled.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/11/Todo-viene-y-va-mp3-image.jpg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/11/LB-TAPA.jpg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/11/LB-TAPA.jpg",
    location: "México",
    city: "Ciudad de México",
    artistInfo: {
      bio: "Compositor profesional de música Pop , Rock y Urbana...",
      bio2: "Compositor profesional de música Pop , Rock y Urban.Productor de vasta experiencia. Vocal Coach y director vocal de artistas reconocidos. (Alejandra Guzmán, Yahir, Carlos Rivera, etc.) Programador en ProTools.",
      socialMedia: {
        facebook: "https://facebook.com/artist2",
        twitter: "https://twitter.com/artist2",
        instagram: "http://www.instagram.com/hecs.__",
        email: "jorgemontesano@hotmail.com",
        Telephone: "+52-5514736515",
        Directions: "Fernando Villalpando 110. D403. Colonia Guadalupe Inn. CP 01020"
      }
    },
    playlist: [
      {
        title: "Todo viene y va",
        artist: "MONTESANO",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/11/Todo-viene-y-va.mp3"
      },
      {
        title: "Sera",
        artist: "MONTESANO",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/11/Sera-1-1.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Ya no se",
        artist: "MONTESANO",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/11/02-Ya-no-se.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
    ],
    categories: ['Productor Musical','Beatmaker','Cantante','Compositor',],
    tags: ['Drill', 'Latin', 'Rkt','Hip-hop','Regueton','Trap'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "Dímelo Ferv",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/photo_2023-09-21_17-14-23.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/photo_2023-09-21_16-08-35.jpg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/57-302-6882212-20230921_180634-1.jpg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/photo_2023-09-21_17-14-23.jpg",
    location: "Venezuela",
    city: "Zulia",
    url: "https://youtu.be/zojojqLoNWg",
    artistInfo: {
      bio: "llevo más de 7 Años dedicándome a la música...",
      bio2: "Hola, Mi nombre es Dímelo Ferv y llevo más de 7 Años dedicándome a la música. Especialmente al género urbano, Soy Productor Musical, llevo las riendas del sello Independiente Intelectual Music. Lo más importante es sacar todo el potencial que tienes tú como artista, de eso nos encargaremos nosotros resaltando lo mejor de ti en cada canción o Instrumental. Llevaremos tu música a otro nivel.",
      socialMedia: {
        facebook: "https://www.facebook.com/IntelectualMusicLtd",
        twitter: "https://twitter.com/artist2",
        instagram: "https://www.instagram.com/intelectualmusicltd/",
        email: "intelectualmusicltd.bookings@gmail.com",
        Telephone: "+584246636821",
        Directions: "Municipio Maracaibo, Estado Zulia, Venezuela"
      }
    },
    playlist: [
      {
        title: "BENZ",
        artist: "Dímelo Ferv",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/BENZ-Prod.-Dimelo-Ferv.mp3"
      },
      {
        title: "1% ",
        artist: "Dímelo Ferv",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/1XCIENTO-Prod.-Dimelo-Ferv.mp3",
        image: "https://via.placeholder.com/150/FF0000/FFFFFF?Text=Song2",
      },
      {
        title: "SUSANA",
        artist: "Dímelo Ferv",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SUSANA-Prod.-Dimelo-Ferv.mp3",
        image: "https://via.placeholder.com/150/FF0000/FFFFFF?Text=Song2",
      },
    ],
    categories: ['Productor Musical'],
    tags: ['Dance Hall', 'Electro Pop', 'Hip-Hop','Pop',
      'Reggae','Trap','Drill','Electronica-Dance','House','Rb-soul',
      'Regueton','Urbano'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "Braulio Polanco",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/3.png",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/1.png",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/4.png",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Diseno-sin-titulo-1.png",
    location: "Venezuela",
    url: "https://www.youtube.com/embed/zojojqLoNWg",
    city: "Zulia",
    artistInfo: {
      bio: " Licenciado en música con 10 años de experiencia como productor...",
      bio2: " Licenciado en música con 10 años de experiencia como productor musical y multiinstrumentista",
      socialMedia: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/braulioficial_/?hl=de",
        email: "braulioficial@gmail.com",
        Telephone: "+58 04149669692",
        Directions: "La Floresta, AV 86, Parroquia Raúl Leoni, Municipio Maracaibo, Estado Zulia, 4032, Venezuela"
      }
    },
    playlist: [
      {
        title: "DIMMI COME",
        artist: "Braulio Polanco",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/DIMMI-COME.mp3"
      },
      {
        title: "FATE WONT PLAY MY DESIRES",
        artist: "Braulio Polanco",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/FATE-WONT-PLAY-MY-DESIRES.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "NEVER WANNA GIVE YOU UP 1",
        artist: "Braulio Polanco",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/NEVER-WANNA-GIVE-YOU-UP-1.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
    ],
    categories: ['Productor Musical','Composicion','Cantante','Compositor',
      'Mezcla','Mastering','Instrumentales','Grabacion', ],
    tags: ['Gospel', 'Pop', 'Rock','Latin','Reggae','Urbano'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "JR Producciones",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/IMG_20230826_225248.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/IMG_20230827_152123.jpg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Screenshot_2nrtnrtn.png",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/JR-2.jpeg",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=ZsxZA69n5F4",
    city: "Lima",
    artistInfo: {
      bio: " 🤝𝐇𝐨𝐥𝐚 𝐭𝐞 𝐒𝐚𝐥𝐮𝐝𝐚 𝐏𝐫𝐨𝐝𝐮𝐜𝐭𝐨𝐫𝐚 𝐉𝐑 /// 𝐋𝐢𝐦𝐚 - 𝐏𝐞𝐫ú 🇵🇪",
      bio2: "No Disponible",
      socialMedia: {
        facebook: "https://www.facebook.com/jrproduccionesperuoficial/",
        twitter: "",
        instagram: "",
        email: "jrproduccionesperu1@gmail.com",
        Telephone: "+51 952438870",
        Directions: "Lote 21 Avenida Tantamayo MZ B San Martín de Porres 15113 Perú"
      }
    },
    playlist: [
      {
        title: "Para darte Fuego / Audio Producción/ Salsa ",
        artist: "JR Producciones",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/PARA-DARTE-FUEGO-MASTER-2.mp3"
      },
      {
        title: "Ironía / Audio Producción/ Salsa ",
        artist: "JR Producciones",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/IRONIA-MASTER-1-1.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "La Manguera / Audio Comercial / Pandilla 2023",
        artist: "JR Producciones",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/La-Manguera-2023-Pandilla-Primicia.wav",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Mix Por perro & Mi cama / Pista Musical Sesión Studio en Vivo / Timba",
        artist: "JR Producciones",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/10/Mix-Por-perro-Mi-cama-_-Pista-Musical-Sesion-Studio-en-Vivo-_-Timba.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      
    ],
    categories: ['Productor Musical','Composicion','Mezcla','Mastering','Instrumentales','Grabacion', ],
    tags: ['Bachata', 'Boleros', 'Corriditos Ecuatorianos','Cumbia','Huyanos','Lambadas','Música de la selva','Ranchera',
      'Rock','Saya','Villancicos','Baladas','Chichas','Criollos','Jingles','Merengue'
      ,'Pandillas','Regueton','Salsa','Timba'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "la rana en el beat",
    image: "https://panel.productordemusica.com/wp-content/uploads/2024/09/P1570444-1-scaled.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2024/09/P1570448-768x1023-1.jpg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2024/09/P1570448-768x1023-1.jpg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2024/09/P1570448-768x1023-1.jpg",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=MJZKifme_K0",
    city: "Lima",
    artistInfo: {
      bio: "Productor con mas de 12 Años de Exeriencia",
      bio2: "PRODUCTOR CON MAS DE 12 AÑOS DE EXPERIENCIA Por su parte,La Rana En El Beat siempre se ha mantenido como uno de los mejores productores top nacionales de los últimos tiempos, pues se le vio en una foto junto a Leslie Shaw en su productora Diem Studios anunciando una próxima colaboración.También se ha involucrado en diversos proyectos con un amplio abanico de géneros, artistas y productores internacionales, tales como Young Hollywood, Yazo, El Mago de Oz, Mosty, Lil Wizard, Dj Luian, Las Guanabanas, Nova del dúo Nova y Jory, Rashan, Marvin El Protagonista, JUANKA EL PROBLEMATIC, NICKY JAM, KITTY DE LAS CULISUELTAS NACIONALES AFRODISIACO, NSAMBLE, EL LEON Y SU TRIPULACION, LESLIE SHAW, STRONG BLACK, Y MAS Mis Proyectos: La Rana En El Beat a estado involucrado en diversos proyectos con un amplio abanico de géneros, artistas y productores internacionales, tales como Young Hollywood, Yazo, El Mago de Oz, Mosty, Lil Wizard, Dj Luian, Las Guanabanas, Nova del dúo Nova y Jory, Rashan, Marvin El Protagonista, JUANKA EL PROBLEMATIC, NICKY JAM, KITTY DE LAS CULISUELTAS NACIONALES AFRODISIACO, NSAMBLE, EL LEON Y SU TRIPULACION, LESLIE SHAW, STRONG BLACK, Y MAS",
      socialMedia: {
        facebook: "https://www.facebook.com/LARANAENELBEAT/",
        twitter: "",
        instagram: "https://www.instagram.com/laranaenelbeat/?hl=es",
        email: "diemlarana@gmail.com",
        Telephone: "+51941415935",
        Directions: "no dispobible"
      }
    },
    playlist: [
      {
        title: "Say My Name - La Rana En El Beat, @akeellah & @Neracheka",
        artist: "la rana en el beat",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Say-My-Name-La-Rana-En-El-Beat-@akeellah-@Neracheka.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Pacto remix - El Avatar de cuba y La Rana En El Beat",
        artist: "la rana en el beat",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Pacto-remix.El-Avatar-de-cuba-y-La-Rana-En-El-Beat.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "6ix9ine Bori feat. Lenier",
        artist: "la rana en el beat",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/6ix9ine-Bori-feat.-Lenier-La-Rana-En-El-Beat.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      }, 
      {
        title: "Shaka Laka - La Rana En El Beat (Ft. Ator Untela, El Avatar de Cuba, Mary Flow & Ferrique)",
        artist: "la rana en el beat",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Shaka-Laka-La-Rana-En-El-Beat-Ft.-Ator-Untela-El-Avatar-de-Cuba-Mary-Flow-Ferrique.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "No Hubo Love",
        artist: "la rana en el beat",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/La-Rana-En-El-Beat-No-Hubo-Love.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "PRENDELO",
        artist: "la rana en el beat",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/LaRanaEnElBeat-@Alainalmeida-@ElAvatarDeCuba-PRENDELO.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      
    ],
    categories: ['Productor Musical','Composicion','Mezcla','Mastering','Instrumentales','Grabacion','Beatmaker'  ],
    tags: ['Cumbia','Drill','Regueton','Rkt','Trap',
      'Dance Hall','House','Reparto','Salsa'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "Jairo Betancourt",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/FB_IMG_1628281444412.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/FB_IMG_1628958056064.jpg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/FB_IMG_1663954287089.jpg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/FB_IMG_1677893771756.jpg",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=M4NRYg8Q_MI",
    city: "Lima",
    artistInfo: {
      bio: "Productor con mas de 12 Años de Exeriencia",
      bio2: "Conoce el Productor:-Egresado del Conservatorio de Música Amedeo Roldan de La Habana cuba -Director de Orquesta -Arreglista -Pianista -Trompetista. Referencias del Productor: Michael jackson, Pharrell Williams, Earth Wind & Fire",
      socialMedia: {
        facebook: "",
        twitter: "",
        instagram: "",
        email: "trompeta23@gmail.com",
        Telephone: "+51974718201",
        Directions: "Aguirre, Avenida Santiago de Surco, La Capullana, Santiago de Surco, Lima, Lima Metropolitana, Lima, 15038, Perú"
      }
    },
    playlist: [
      {
        title: "Fat Fresh Contigo x Mera De La Rosa",
        artist: "Jairo Betancourt",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Fat-Fresh-Contigo-x-Mera-De-La-Rosa-Video.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Te doy mi amor (live) - Jose Ignacio",
        artist: "Jairo Betancourt",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/cap.-3-Te-doy-mi-amor-Jose-Ignacio-Debajo-de-los-Suspiros.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      }, 
      {
        title: "Cotto Music ft Leo Fabre Su Cancion",
        artist: "Jairo Betancourt",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Cotto-Music-ft-Leo-Fabre-Su-Cancion.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Real Guerrero Jibarito Chalaco",
        artist: "Jairo Betancourt",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/REAL-GUERRERO-JIBARITO-CHALACO-VERSION-SALSA-2023-NewVideo.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      
      
    ],
    categories: ['Productor Musical','Mezcla','Mastering','Instrumentales','Grabacion' ],
    tags: ['Bachata','Merengue','Reparto','Timba','Latin',
      'Regueton','Salsa','Urbano'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "Leonardo Iván Díaz",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_14.png",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_13.png",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_15.png",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_16.png",
    location: "Perú",
    url: "",
    city: "Lima",
    artistInfo: {
      bio: "He trabajado con artistas como: Bartola, Anid, Salim Vera.. entre otros",
      bio2: "Estudios de Tecnología en Sonido en Universidad Tecnológica de Chile INACAP-Sede Pérez Rosales. Co-Fundador de PTY Studios (Panamá) . Rabanes, Rubén Blades...entre otros. Aldaba Records (Lima). Líbido, The Outsiders, Proyecto de La Lama...entre otros. E.O.G Producciones (Lima). Eva Ayllón, Jean Piere Magnet, Bareto, We the Lion...entre otros. He trabajado con artistas como: Bartola, Anid, Salim Vera & Two Digital Boys, Gabriel Herrera, Jhovan Tomasevich & Two Digital Boys, Dabau, AmaRetta Me especializo en: Producción, arreglo, grabación, programación MIDI, diseño de sonido, coros, edición y mezcla de audio Mis Proyectos: -Desde el Alma (Bartola) -Anid (Por estos caminos) -Kamikaze (Los Rabanes)",
      socialMedia: {
        facebook: "",
        twitter: "",
        instagram: "",
        email: "leonardoivandiaz@yahoo.com",
        Telephone: "+51991147359",
        Directions: "Lima, Lima Metropolitana, Lima, 15038, Perú"
      }
    },
    playlist: [
      {
        title: "Por Estos Caminos (Luz Divina) - Anid",
        artist: "Leonardo Iván Díaz",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Por-Estos-Caminos-Luz-Divina.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Pancha Remolino",
        artist: "Leonardo Iván Díaz",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Pancha-Remolino.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      }, 
      {
        title: "Eletric Avenue",
        artist: "JLeonardo Iván Díaz",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Eletric-Avenue.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Ven",
        artist: "Leonardo Iván Díaz",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Ven.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      
      
    ],
    categories: ['Productor Musical','Mezcla','Mastering','Instrumentales','Arreglo','Programación MIDI','Coros','Diseño de Sonido','Edición' ],
    tags: ['Folk','Salsa','Reparto','World Music'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },

  {
    name: "Kung",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_6-1.png",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_5-1.png",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_6-1.png",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_5-1.png",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=sHjGKYefvZ4",
    city: "Lima",
    artistInfo: {
      bio: "Tengo más de una década dedicándome a la producción musical, cuento con una gran cantidad de metas alcanzadas ya que desde muy joven empecé trabajando en en mastering y mezcla ",
      bio2: "Tengo más de una década dedicándome a la producción musical, cuento con una gran cantidad de metas alcanzadas ya que desde muy joven empecé trabajando en en mastering y mezcla hasta que logre con mucha disciplina logra ser el profesional que actualmente soy. dentro de mis metas alcanzadas es poder tener un estudio propio que cuentas con los requerimientos que considero necesarios para cubrir todas las necesidades de la industria, como productor y ser humano me gustas involúcrame con el artista y tomarme el tiempo de lograr ese sonido único que ambos sabemos que es único por cada pieza de arte que creamos. Referencias del Productor: mis referencian inicialmente seria la música América, hoy por hoy es la referencias de sonido que me gusta lograr ya que manejan las tendencias y siempre están innovando en la industria musical, dentro de los artistas referentes puedo destacar a ed sheeran, post malone , adele , blink-182, Muse, Drake y The Weeknd. Mis Proyectos: -Aldo Coz and the Locals -Marcel Caillaux -Mava Ro-Bettone",
      socialMedia: {
        facebook: "https://www.facebook.com/dojosoundstudio",
        twitter: "",
        instagram: "https://www.instagram.com/dojosoundstudio/",
        email: "dojosoundstudio@gmail.com",
        Telephone: "+51969745088",
        Directions: "Institución Educativa 07 La Inmaculada, 190, Jirón Santa Rosa, Barranco, Lima, Lima Metropolitana, Lima, 15063, Perú"
      }
    },
    playlist: [
      {
        title: "Iceman - Aldo Coz &amp; the LoCaLs",
        artist: "Kung",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Iceman.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "Fly - Bettone",
        artist: "Kung",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Fly.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      }, 
      {
        title: "Con Ella - Marcel Caillaux",
        artist: "Kung",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/SPOTIFY-DOWNLOADER.COM-Con-Ella.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },            
      
    ],
    categories: ['Productor Musical','Mezcla','Mastering','Instrumentales','Diseño de Sonido','Edición','Grabacion' ],
    tags: ['Rock','Reggae','Punk','Baladas','Pop'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "César Chima",
    image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_13124512352-1.png",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_2cewc.png",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_2123523512.png",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2023/09/Screenshot_2123523512.png",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=QC1NqURt2r0",
    city: "Chiclayo",
    artistInfo: {
      bio: "Músico y productor musical, actualmente se desarolla como principal productor en A&M Music de Alex Muñoz (Lima) ",
      bio2: "Músico y productor musical, actualmente se desarolla como principal productor en A&M Music de Alex Muñoz (Lima), además de colaborar en las producciones de estudio de MAYRA GUZMÁN, ZONA LIBRE, NICOLAS PARRA, YSABEL SEVILLANO, ALEX MUÑOZ, LOS KIPUS, LOS SICANES, entre otros. En su haber ha tenido la responsabilidad de mezclar y masterizar producciones para Cosa nuestra de Tito Manrique Teatro en Grande, Cosa nuestra en el Jazz Zone, etc) el nuevo disco de bachata de Alex Muñoz, álbum ROMANCES LA GUZMÁN,entre otros proyectos de artistas emergentes en los diversos géneros musicales. Su amplio talento para la música, lo llevó en el año 2020 a producir un disco propio, llamado QUE SE ESCUCHE MI VOZ, en un género totalmente peruano, contando allí con músicos del más alto nivel.",
      socialMedia: {
        facebook: "https://www.facebook.com/dojosoundstudio",
        twitter: "",
        instagram: "https://www.instagram.com/dojosoundstudio/",
        email: "cesitarchima@gmail.com",
        Telephone: "+51956007964",
        Directions: "Institución Educativa 07 La Inmaculada, 190, Jirón Santa Rosa, Barranco, Lima, Lima Metropolitana, Lima, 15063, Perú"
      }
    },
    playlist: [
      {
        title: "Olé olé olé olá - La Guzman Afro timba",
        artist: "César Chima",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/Ole-ole-ole-ola-La-Guzman-Afro-timba.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },     
      {
        title: "SI ME AMARAS - MAYRA GUZMÁN",
        artist: "César Chima",
        src: "https://panel.productordemusica.com/wp-content/uploads/2023/09/y2mate.is-SI-ME-AMARAS-.-MAYRA-GUZMAN-LzODqd5HOoY-192k-1694728408.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },
      {
        title: "ERA MI VIDA EL - MAYRA GUZMAN",
        artist: "César Chima",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/ERA-MI-VIDA-EL-.-MAYRA-GUZMAN.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      }, 
      {
        title: " El Anillo Pa Cuando Afrobeat",
        artist: "César Chima",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/La-Guzman-El-Anillo-Pa-Cuando-Afrobeat.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },     {
        title: "AMNESIA ",
        artist: "César Chima",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/09/AMNESIA.LA-GUZMAN-version-vals.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },     {
        title: "QUÉ SERÁ DE TI LA GUZMÁN",
        artist: "César Chima",
        src: "https://www.youtube.com/watch?v=bwH2NLJO0Wg&ab_channel=MayraGuzman",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },     
      
      
    ],
    categories: ['Productor Musical','Mezcla','Mastering','Edición','Grabacion' ],
    tags: ['Cumbia Fusión','Música Peruana','Música Afroperuana','Tropical Peruano'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "CER",
    image: "https://panel.productordemusica.com/wp-content/uploads/2024/10/photo_2023-12-13_16-52-43.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-09-11-at-2.38.15-PM.jpeg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-09-11-at-2.38.15-PM-1.jpeg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2024/10/Peliculas-y-TV-31_01_2024-20_19_50.png",
    location: "Perú",
    url: "https://www.youtube.com/watch?v=YWJ1_-IaolE",
    city: "Lima",
    artistInfo: {
      bio: "Productor Musical, Mezcla y mastering, Clases de mezcla y mastering.... ",
      bio2: "Productor Musical, Mezcla y mastering, Clases de mezcla y mastering, estudio de grabacion, Asesorias y arreglos musicales. Beat maker, musico. clases de teoria musical.",
      socialMedia: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/ce_rmusic/",
        email: "cermusiconthehate@gmail.com",
        Telephone: "+51927716011",
        Directions: ""
      }
    },
    playlist: [
      {
        title: "ON FEM SAW 2",
        artist: "CER",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/10/ON-FEM-SAW-2.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },     
      {
        title: "Let me kiss then 4",
        artist: "CER",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/10/Let-me-kiss-then-4.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },         
      
    ],
    categories: ['Productor Musical','Mezcla','Mastering','Edición','Estudio de Grabación', 
      'Arreglos','Beatmaker','Músico' ],
    tags: ['Hip-Hop','Dance Hall','Drill','Electronica-dance', 'Hip-Hop','House','Música Peruana','Música Peruana', 'Rkt', 'Regueton','Trap','Rb-soul'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },
  {
    name: "UNespartano",
    image: "https://panel.productordemusica.com/wp-content/uploads/2024/10/FB_IMG_1718177353569-2-201x300.jpg",
    image1: "https://panel.productordemusica.com/wp-content/uploads/2024/10/FB_IMG_1718177353569-2-201x300.jpg",
    image2: "https://panel.productordemusica.com/wp-content/uploads/2024/10/FB_IMG_1718177353569-2-201x300.jpg",
    image3: "https://panel.productordemusica.com/wp-content/uploads/2024/10/FB_IMG_1718177353569-2-201x300.jpg",
    location: "Perú",
    url: "https://www.youtube.com/embed/dbIeXySHKKM?si=X91rLHvCP6qqhURc",
    city: "Lima",
    artistInfo: {
      bio: "Soy un productor musical creativo y apasionado de la música urbana..... ",
      bio2: "Soy un productor musical creativo y apasionado de la música urbana. Me especializo en crear pistas únicas y personalizadas para cada artista. La música es un medio poderoso para transmitir emociones y contar historias. Mi objetivo es ayudar a los artistas a dar a conocer su mensaje y conectar de manera auténtica con su público, creando juntos obras que resuenen en el corazón de las personas.",
      socialMedia: {
        facebook: "https://www.facebook.com/profile.php?id=61553190367751&mibextid=ZbWKwL",
        twitter: "",
        instagram: "https://www.instagram.com/onespartano/profilecard/?igsh=djdneXRxdWIwdGVn",
        email: "leonjhosmar1994@gmail.com",
        Telephone: "+51916233741",
        Directions: "Chorrillos, Lima, Lima Metropolitana, Lima, Perú"
      }
    },
    playlist: [
      {
        title: "Voy a mi",
        artist: "UNespartano",
        src: "https://panel.productordemusica.com/wp-content/uploads/2024/10/Voy-a-Mi.mp3",
        image: "https://panel.productordemusica.com/wp-content/uploads/2023/09/pexels-dmitry-demidov-3810824-scaled.jpg",
      },     
          
      
    ],
    categories: ['Beatmaker' ],
    tags: ['Drill', 'Hip-Hop','Reggaeton','Trap','Rb-soul'],
    coordinates: { lat: 51.5074, lng: -0.1278 }
  },




  // Puedes agregar más artistas aquí...
];

export default artists;
