import React, { useState } from 'react';
import './Precio.css';
import FAQ from './FAQ';


const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState('yearly');

  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
  };

  const plans = [
    {
      name: 'Gratis',
      price: billingCycle === 'yearly' ? 0 : 0,
      features: ['Campañas de gran alcance', 'Publicidad optimizada', 'Contenido multimedia personalizado', 'Análisis avanzado', 'Consultoría estratégica (5h/mes)', 'Asesoría en branding'],
      url: 'https://tu-sitio.com/plan-avanzado' // URL de redirección del plan Avanzado
    },
    {
      name: 'Básico',
      price: billingCycle === 'yearly' ? 180 : 20,
      features: ['Estrategia básica de redes sociales', '4-8 publicaciones mensuales', 'Monitoreo mensual', 'Consultoría básica (1h/mes)'],
      recommended: true,
      url: 'https://tu-sitio.com/plan-basico' // URL de redirección del plan Básico
    },
    {
      name: 'Intermedio',
      price: billingCycle === 'yearly' ? 800 : 75,
      features: ['Planificación de contenido semanal', '12-16 publicaciones mensuales', 'Gestión y respuesta de redes', 'Campañas pagadas básicas', 'Consultoría en marketing (2-3h/mes)'],
      url: 'https://tu-sitio.com/plan-intermedio' // URL de redirección del plan Intermedio
    },
    {
      name: 'Avanzado',
      price: billingCycle === 'yearly' ? 2500 : 220,
      features: ['Campañas de gran alcance', 'Publicidad optimizada', 'Contenido multimedia personalizado', 'Análisis avanzado', 'Consultoría estratégica (5h/mes)', 'Asesoría en branding'],
      url: 'https://tu-sitio.com/plan-avanzado' // URL de redirección del plan Avanzado
    }
  ];

  return (
    <div className="pricing-container">
      <h1>Planes de Precios</h1>
      <p>Selecciona el ciclo de facturación</p>
      <div className="toggle-button">
        <span>Mensual</span>
        <div onClick={toggleBillingCycle} className="toggle-switch">
          <div className={`switch ${billingCycle === 'yearly' ? 'right' : 'left'}`} />
        </div>
        <span>Anual (20% de descuento)</span>
      </div>
      
      <div className="plans">
        {plans.map((plan, index) => (
          <div key={index} className={`plan ${plan.recommended ? 'recommended' : ''}`}>
            {plan.recommended && <span className="recommended-tag">Plan Recomendado</span>}
            <h2>{plan.name}</h2>
            <p className="price">${plan.price}{billingCycle === 'yearly' ? '/año' : '/mes'}</p>
            <p className="description">Ideal para {plan.name.toLowerCase()}s.</p>
            <button onClick={() => window.location.href = plan.url}>Comenzar</button>
            <ul>
              {plan.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <FAQ />
    </div>
  );
};

export default Pricing;
