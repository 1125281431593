import React, { useMemo } from 'react';
import TalentProfileForm from './TalentProfileForm'; // Asegúrate de que la ruta sea correcta
import App from './App'; // Asegúrate de que la ruta sea correcta


function Registro() {
    const tags = useMemo(() => [
        'Pop', 'Rock', 'Jazz', 'Hip-Hop', 'Drill', 'Latin', 'Rkt', 'Reggaeton','Trap','Bachata','Boleros',
        'Cumbia','Dance Hall','Funk','House','Reggae','Baladas','Bossa-nova','Cumbia Fusión',
        'Electronica-dance','Electro Pop','Rb-soul','Ambiente','Barroco','Blues','Chichas',
        'Clasica','Corriditos Ecuatorianos','Country','Criollos','Cumbia','Cumbia Ecuatoriana',
        'Disco','Electro Pop','Experimental','Flamenco','Folk','Funk','Fusion','Gospel','Hard-rock',
        'Huyanos','Indie-alternativo','Jingles','K-Pop','Lambadas','Merengue','Metal','Música Afro',
        'Música de la selva','Pandillas','Post-Rock','Punk','Ranchera','Reparto','Salsa','Saya','Ska',
        'Timba','Trap','Tropical','Villancicos','World Music','Villenatos'
      ], []);
      const categories = useMemo(() => [
        'Productor Musical', 'Cantantes', 'Músico', 'Beatmaker', 'Compositor', 
        'Arreglos', 'Autor', 'Coros', 'Diseño de Sonido', 'DJ', 'Edición', 'Estudio de Grabación', 
        'Grabacion', 'Instrumentales','Locución','Mastering','Mezcla','Músicos de sesión','Bajistas',
        'Baterista','Guitarrista','Percusionista','Pianista','Teclados','Ukulele','Trompeta','Charangos',
        'Violín','Producción de Audio Libro','Producción de Podcast','Programación MIDI',
        'Remixing','Vocal Coach','Voz en off y narración'
      ], []);
  const countryData = useMemo(() => ({
    "Argentina": {
      cities: ["Buenos Aires", "Córdoba", "Rosario"],
      code: "+54"
    },
    "México": {
      cities: ["Ciudad de México", "Guadalajara", "Monterrey"],
      code: "+52"
    },
    "España": {
      cities: ["Madrid", "Barcelona", "Valencia"],
      code: "+34"
    },
    "Venezuela": {
      cities: ["Zulia",],
      code: "+58"
    },
  }), []);

  return (
    <div>
      <TalentProfileForm 
        categories={categories} 
        tags={tags} 
        countryData={countryData} 
      />
    </div>
  );
}

export default Registro;
