import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ArtistPage from './ArtistPage';
import Registro from './Registro';
import Blog from './Blog';
import Precio from './Precio';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/artist/:artistName" element={<ArtistPage />} />
        <Route path="/Registro" element={<Registro />} />
        <Route path="/Blog" element={<Blog/>} />
        <Route path="/Precio" element={<Precio/>} />
      </Routes>
    </Router>
  </HelmetProvider>
);

reportWebVitals();
