import React, { useState, useRef, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import './ArtistPage.css';
import artists from './artistsData';
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaPlayCircle,
  FaPauseCircle,
  FaTimes // Importar el icono de cierre
} from 'react-icons/fa';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const ArtistPage = () => {
  const { artistName } = useParams();
  const [artist, setArtist] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef(null);

  useEffect(() => {
    const artistData = artists.find((artist) => artist.name === artistName);
    setArtist(artistData);
  }, [artistName]);

  const handleSongSelect = (song) => {
    if (currentSong?.src === song.src) {
      setIsPlaying(!isPlaying);
    } else {
      setCurrentSong(song);
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (audioPlayerRef.current) {
      if (isPlaying) {
        audioPlayerRef.current.audio.current.play();
      } else {
        audioPlayerRef.current.audio.current.pause();
      }
    }

    const handleEnd = () => {
      setIsPlaying(false);
    };

    if (audioPlayerRef.current) {
      audioPlayerRef.current.audio.current.addEventListener('ended', handleEnd);
    }

    return () => {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.audio.current.removeEventListener('ended', handleEnd);
      }
    };
  }, [isPlaying, currentSong]);

  // Función para cerrar el reproductor
  const handleClosePlayer = () => {
    setCurrentSong(null);
    setIsPlaying(false);
  };

  if (!artist) {
    return <div className="artist-not-found">Artista no encontrado</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="artist-page2">
      <Helmet>
        {/* Helmet para SEO dinámico */}
        <title>{`${artist.name} - PDM`}</title>
        <meta
          name="description2"
          content={`Escucha las mejores canciones de ${artist.name}. ${
            artist.artistInfo?.bio || 'Conoce más sobre este talentoso artista.'
          }`}
        />
        {/* Otros metadatos... */}
      </Helmet>

      {/* Barra de navegación */}
<nav className="navbar2">
  <div className="navbar-logo2">
    <Link to="/">
      <img src="/logopdm.png" alt="Logo" />
    </Link>
  </div>

  {/* Texto centrado */}
  <div className="navbar-center-text2">
    <Link to="/" className="center-text2"> {artist.name}</Link>
  </div>

  <div className="navbar-links2">
    <a
      href="https://productordemusica.info/registro/"
      className="nav-button2"
    >
      Regístrate
    </a>
    <a
      href="https://productordemusica.info/anadir-un-anuncio/?directory_type=proyectos"
      className="nav-button2"
    >
      Crea un Proyecto
    </a>
  </div>
</nav>


      {/* Encabezado con título y perfil del artista */}
      <header className="artist-header2">
        <h1 className="page-title2">{artist.name}</h1> {/* Mover título aquí */}
        {artist.image1 && artist.image2 && artist.image3 ? (
          <Slider {...settings} className="image-carousel2">
            {[artist.image1, artist.image2, artist.image3].map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Imagen2 ${index + 1}`} className="carousel-image2" />
              </div>
            ))}
          </Slider>
        ) : (
          <p>No hay imágenes disponibles</p>
        )}

        <div className="artist-profile2">
          <img src={artist.image} alt={`${artist.name} Perfil2`} className="profile-image2" />
          <div className="artist-header-info2">
            <p>{artist.artistInfo?.bio || 'Sin biografía disponible'}</p>
            <p><strong>País:</strong> {artist.location}</p>
            <p><strong>Ciudad:</strong> {artist.city}</p>
          </div>
        </div>
      </header>

      {/* Playlist */}
      <section className="artist-playlist3">
        <h2>Playlist de {artist.name}</h2>
        {artist.playlist && artist.playlist.length > 0 ? (
          <ul className="playlist2">
            {artist.playlist.map((song, index) => (
              <li
                key={index}
                className={`playlist-item2 ${currentSong?.src === song.src ? 'active' : ''}`}
                onClick={() => handleSongSelect(song)}
              >
                <img src={song.image} alt={song.title} className="song-image2" />
                <div className="song-details2">
                  <h3>{song.title}</h3>
                  <p>{song.artist}</p>
                </div>
                <div className="play-indicator2">
                  {currentSong?.src === song.src && isPlaying ? (
                    <FaPauseCircle size={30} color="#ddd" />
                  ) : (
                    <FaPlayCircle size={30} color="#ddd" />
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay canciones disponibles en la playlist</p>
        )}

        

        
      </section>
      <section className="artist-details2">
        <div className="artist-youtube2">
          <h3>Video Promocional</h3>
          {artist.url ? (
            <iframe
              width="560"
              height="315"
              src={artist.url.replace("watch?v=", "embed/")}
              title={artist.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <p>No hay video disponible</p>
          )}
        </div>
        <div className="artist-social-media2">
          <h3>Sígueme en:</h3>
          <div className="social-icons2">
            {artist.artistInfo?.socialMedia?.facebook && (
              <a href={artist.artistInfo.socialMedia.facebook} target="_blank" rel="noopener noreferrer" className="social-icon2">
                <FaFacebookSquare size={32} />
              </a>
            )}
            {artist.artistInfo?.socialMedia?.twitter && (
              <a href={artist.artistInfo.socialMedia.twitter} target="_blank" rel="noopener noreferrer" className="social-icon2">
                <FaTwitterSquare size={32} />
              </a>
            )}
            {artist.artistInfo?.socialMedia?.instagram && (
              <a href={artist.artistInfo.socialMedia.instagram} target="_blank" rel="noopener noreferrer" className="social-icon2">
                <FaInstagramSquare size={32} />
              </a>
            )}
          </div>
        </div>
      </section>

      <section className="artist-categories-tags2">
      <div className="artist-categories2">
      <h3>Biografia:</h3>
      <p>{artist.artistInfo?.bio2 || 'Sin biografía disponible'}</p>

      </div>

        <div className="artist-categories2">
          <h3>Habilidades:</h3>
          {artist.categories && artist.categories.length > 0 ? (
            <ul>
              {artist.categories.map((category, index) => (
                <li key={index}>{category}</li>
              ))}
            </ul>
          ) : (
            <p>No hay categorías disponibles</p>
          )}

<div className="artist-tags2">
          <h3>Géneros:</h3>
          {artist.tags && artist.tags.length > 0 ? (
            <ul>
              {artist.tags.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
            </ul>
          ) : (
            <p>No hay etiquetas disponibles</p>
          )}
        </div>
        </div>
     
      </section>


      {/* Reproductor de Audio Personalizado */}
      {currentSong && (
        <div className="custom-audio-player-container2">
          <div className="close-button2" onClick={handleClosePlayer}>
            <FaTimes size={20} color="#ddd" /> {/* Botón de cierre */}
          </div>
          <div className="album-cover2">
            <img src={currentSong.image} alt={currentSong.title} />
          </div>
          <div className="track-info2">
            <h3>{currentSong.title}</h3>
            <p>{currentSong.artist}</p>
          </div>
          <AudioPlayer
            ref={audioPlayerRef}
            src={currentSong.src}
            autoPlay={isPlaying}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            showSkipControls={false}
            showJumpControls={false}
            customAdditionalControls={[]}
            customVolumeControls={['VOLUME']}
            layout="horizontal"
            className="custom-audio-player"
          />
        </div>
      )}
    </div>
  );
};

export default ArtistPage;

