import React, { useState, useMemo, useEffect, useReducer, useRef } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { FaSun, FaMoon, FaPlay, FaPause, FaBars, FaTimes, FaTh, FaList, FaFilter, FaMusic } from 'react-icons/fa';
import { FaMapLocation } from 'react-icons/fa6';
import Select from 'react-select';
import './App.css';
import artists from './artistsData';

const initialState = {
  isShuffle: false,
  isRepeat: false,
  isPlaying: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_SHUFFLE':
      return { ...state, isShuffle: !state.isShuffle };
    case 'TOGGLE_REPEAT':
      return { ...state, isRepeat: !state.isRepeat };
    case 'TOGGLE_PLAY':
      return { ...state, isPlaying: !state.isPlaying };
    case 'PLAY':
      return { ...state, isPlaying: true };
    case 'PAUSE':
      return { ...state, isPlaying: false };
    default:
      return state;
  }
}

function App() {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');
  const [queue, setQueue] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [volume, setVolume] = useState(parseFloat(localStorage.getItem('volume')) || 1);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isSidebarLeftOpen, setIsSidebarLeftOpen] = useState(false);
  const [isSidebarRightOpen, setIsSidebarRightOpen] = useState(false);
  const [isGridView, setIsGridView] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const categoryCarouselRef = useRef(null);
  const audioPlayerRef = useRef(null);

  
   

  const tags = useMemo(() => [
    'Pop', 'Rock', 'Jazz', 'Hip-Hop', 'Drill', 'Latin', 'Rkt', 'Regueton','Trap','Bachata','Boleros',
    'Cumbia','Dance Hall','Funk','House','Reggae','Baladas','Bossa-nova','Cumbia Fusión',
    'Electronica-dance','Electro Pop','Rb-soul','Ambiente','Barroco','Blues','Chichas',
    'Clasica','Corriditos Ecuatorianos','Country','Criollos','Cumbia','Cumbia Ecuatoriana',
    'Disco','Electro Pop','Experimental','Flamenco','Folk','Funk','Fusion','Gospel','Hard-rock',
    'Huyanos','Indie-alternativo','Jingles','K-Pop','Lambadas','Merengue','Metal','Música Afro',
    'Música de la selva','Pandillas','Post-Rock','Punk','Ranchera','Reparto','Salsa','Saya','Ska',
    'Timba','Trap','Tropical','Villancicos','World Music','Villenatos', 'House','Música Peruana',
  ], []);

  const categories = useMemo(() => [
    'Productor Musical', 'Cantante', 'Musico', 'Beatmaker', 'Compositor', 
    'Arreglos', 'Autor', 'Coros', 'Diseño de Sonido', 'DJ', 'Edición', 'Estudio de Grabación', 
    'Grabacion', 'Instrumentales','Locución','Mastering','Mezcla','Músicos de sesión','Bajistas',
    'Baterista','Guitarrista','Percusionista','Pianista','Teclados','Ukulele','Trompeta','Charangos',
    'Violín','Producción de Audio Libro','Producción de Podcast','Programación MIDI',
    'Remixing','Vocal Coach','Voz en off y narración'
  ], []);

  const countryData = {
    "Argentina": {
      cities: ["Buenos Aires", "Córdoba", "Rosario"],
      code: "+54"
    },
    "México": {
      cities: ["Ciudad de México", "Guadalajara", "Monterrey"],
      code: "+52"
    },
    "España": {
      cities: ["Madrid", "Barcelona", "Valencia"],
      code: "+34"
    },
    "Venezuela": {
      cities: ["Zulia",],
      code: "+58"
    },
    "Perú": {
      cities: ["Lima","Chiclayo",],
      code: "+51"
    },
    // Agrega más países según sea necesario

    
  };

  const songs = useMemo(() => artists.flatMap(artist => artist.playlist), [artists]);
  const categoryOptions = categories.map(category => ({ value: category, label: category }));
  const tagOptions = tags.map(tag => ({ value: tag, label: tag }));
  const countryOptions = Object.keys(countryData).map(country => ({ value: country, label: country }));
  const cityOptions = selectedCountry ? countryData[selectedCountry.value]?.cities.map(city => ({ value: city, label: city })) : [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#121212',
      border: '1px solid #333',
      boxShadow: 'none',
      borderRadius: '4px',
      padding: '4px',
      color: '#fff',
      '&:hover': {
        borderColor: '#9a1af5',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#9a1af5',
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#9a1af5' : state.isFocused ? '#333' : '#282828',
      color: state.isSelected ? '#fff' : '#b3b3b3',
      '&:hover': {
        backgroundColor: '#333',
        color: '#fff',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      borderRadius: '4px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#F21B6A',
        color: 'white',
      },
    }),
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('volume', volume);
  }, [volume]);

  useEffect(() => {
    const applyFilters = () => {
      let updatedArtists = artists;

      if (selectedCategory) {
        updatedArtists = updatedArtists.filter(artist => artist.categories.includes(selectedCategory.value));
      }

      if (selectedTags.length > 0) {
        updatedArtists = updatedArtists.filter(artist => selectedTags.some(tag => artist.tags.includes(tag.value)));
      }

      if (selectedCountry) {
        updatedArtists = updatedArtists.filter(artist => artist.location === selectedCountry.value);
      }

      if (selectedCity) {
        updatedArtists = updatedArtists.filter(artist => artist.city === selectedCity.value);
      }

      setFilteredArtists(updatedArtists);

      const updatedSongs = songs.filter(song =>
        updatedArtists.some(artist => artist.playlist.includes(song))
      );

      setFilteredSongs(updatedSongs);
    };

    applyFilters();
  }, [selectedCategory, selectedTags, selectedCountry, selectedCity, artists, songs]);

  useEffect(() => {
    setFilteredSongs(songs);
    setFilteredArtists(artists);

    const welcomeSongTitle = 'pdmsong1';
    const welcomeSongIndex = songs.findIndex(song => song.title === welcomeSongTitle);

    if (welcomeSongIndex !== -1) {
      setCurrentSongIndex(welcomeSongIndex);
      dispatch({ type: 'PLAY' });

      if (audioPlayerRef.current) {
        const player = audioPlayerRef.current.audio.current;
        player.play().catch(error => {
          console.error("Error al intentar reproducir la canción:", error);
        });
      }
    }
  }, [songs]);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
  
    const filteredArtistsResult = artists.filter(artist =>
      artist.name.toLowerCase().includes(searchValue) ||
      artist.location.toLowerCase().includes(searchValue) ||
      artist.city.toLowerCase().includes(searchValue) ||
      artist.categories.some(category => category.toLowerCase().includes(searchValue)) ||
      artist.tags.some(tag => tag.toLowerCase().includes(searchValue)) ||
      artist.playlist.some(song =>
        song.title.toLowerCase().includes(searchValue) ||
        song.artist.toLowerCase().includes(searchValue)
      )
    );
  
    const filteredSongsResult = songs.filter(song =>
      song.title.toLowerCase().includes(searchValue) ||
      song.artist.toLowerCase().includes(searchValue)
    );
  
    setFilteredArtists(filteredArtistsResult);
    setFilteredSongs(filteredSongsResult);
  
    if (filteredArtistsResult.length === 0 && filteredSongsResult.length === 0) {
      console.log('No se encontraron resultados para la búsqueda, intente de nuevo.');
    }
  };

  const toggleSidebarLeft = () => {
    setIsSidebarLeftOpen(!isSidebarLeftOpen);
  };

  const handleButtonClick = (url) => {
    window.location.href = url;
  };

  const navigate = useNavigate();

  const goToArtistPage = (artistName) => {
    navigate(`/artist/${artistName}`);
  };

  const handleArtistPlayPause = (artist) => {
    const firstSongIndex = songs.findIndex(song => song.title === artist.playlist[0]?.title);
    if (currentSongIndex === firstSongIndex && state.isPlaying) {
      dispatch({ type: 'PAUSE' });
    } else {
      setCurrentSongIndex(firstSongIndex);
      dispatch({ type: 'PLAY' });

      if (audioPlayerRef.current) {
        audioPlayerRef.current.audio.current.play().catch(error => {
          console.error("Error al intentar reproducir la canción:", error);
        });
      }
    }
    setSelectedArtist(artist);
    setIsSidebarRightOpen(true);
  };

  // Función agregada para manejar clics en canciones
  const handleSongClick = (index) => {
    setCurrentSongIndex(index);
    dispatch({ type: 'PLAY' });

    if (audioPlayerRef.current) {
      const player = audioPlayerRef.current.audio.current;
      player.play().catch(error => {
        console.error("Error al intentar reproducir la canción:", error);
      });
    }
  };

  const toggleView = (view) => {
    setIsGridView(view === 'grid');
  };

  const currentSong = filteredSongs[currentSongIndex] || {};

  return (
    <div className="App">
     
      {/* Barra de Navegación Superior */}
      <header className="navbar">
        <button className="sidebar-toggle" onClick={toggleSidebarLeft} aria-label="Toggle left sidebar">
          <FaBars />
        </button>
        <div className="logo-container">
          <a href="/">
            <img src="https://panel.productordemusica.com/wp-content/uploads/2024/09/Copia_de_seguridad_de_nuevo-logo-pdm-sin-fondo.png" alt="Logo" className="logo" />
          </a>
        </div>
        <h1>Productor de Música</h1>
        <input
          type="text"
          placeholder="Encuentra a los mejores Productores, Canatantes, Musicos..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
          aria-label="Buscar"
        />
        <button
          className="nav-button"
          onClick={() => handleButtonClick('https://panel.productordemusica.com/registro/')}
          aria-label="Regístrate"
        >
          Unete
        </button>
        <button
          className="nav-button1"
          onClick={() => handleButtonClick('https://panel.productordemusica.com/anadir-un-anuncio/?directory_type=proyectos')}
          aria-label="Crea un proyecto"
        >
          Contratar Talento
        </button>
      
        </header>     

    



        
      <nav className="horizontal-menu">
      <div className="view-toggle">
              <button className="view-button" onClick={() => toggleView('grid')} aria-label="Ver en cuadrícula">
                <FaTh />
              </button>
                          
              <button className="view-button" aria-label="Ver en Mapa">
                <FaMapLocation />
              </button>
            </div>

      <div className="filters-container">
              <Select
                name="categories"
                options={categoryOptions}
                onChange={setSelectedCategory}
                isClearable
                placeholder="Especialidad"
                value={selectedCategory}
                styles={customStyles}
              />
              <Select
                name="tags"
                options={tagOptions}
                onChange={setSelectedTags}
                isMulti
                placeholder="Géneros"
                value={selectedTags}
                styles={customStyles}
              />
              <Select
                name="country"
                options={countryOptions}
                onChange={(option) => {
                  setSelectedCountry(option);
                  setSelectedCity(null); // Resetear la ciudad cuando se cambia el país
                }}
                isClearable
                placeholder="País"
                value={selectedCountry}
                styles={customStyles}
              />
              <Select
                name="city"
                options={cityOptions}
                onChange={setSelectedCity}
                isClearable
                placeholder="Ciudad"
                value={selectedCity}
                styles={customStyles}
                isDisabled={!selectedCountry} // Desactivar hasta que se seleccione un país
              />
            </div>
    <ul>
    <li className="menu-item">
      <a href="#comunidad">Comunidad</a>
      <ul className="submenu">
        <li><a href="https://productordemusica.com/registro/"><h2>Musicos</h2></a></li>
        <li><a href="#productores"><h2>Productor Musical</h2></a></li>
        <li><a href="#cantantes"><h2>Cantante</h2></a></li>
        <li><a href="#podcasts"><h2>Podcast</h2></a></li>
      </ul>
    </li>
    <li><a href="#oportunidades">Oportunidades</a></li>
    <li><a href="/Blog">Blog</a></li>
    <li><a href="/Precio">Precios</a></li>
    <li><a href="//">Cómo Funciona</a></li>
    <li><a href="//">Talentos Certificados</a></li>
  </ul>
</nav>




      {/* Sidebar Izquierdo */}
      <aside className={`sidebar-left ${isSidebarLeftOpen ? 'open' : 'closed'}`}>
        <button className="sidebar-left-toggle" onClick={toggleSidebarLeft} aria-label="Cerrar sidebar izquierdo">
          <FaTimes />
        </button>
        
        
        <nav>
        <ul>
    <li className="menu-item">
      <a href="">Musicos</a>
      <a href="#comunidad">Productor Musical</a>
      <a href="#comunidad">Cantante</a>
      <a href="#comunidad">Podcast</a>
      <ul className="submenu">
        
      </ul>
    </li>
    <li><a href="#oportunidades">Oportunidades</a></li>
    <li><a href="/Blog">Blog</a></li>
    <li><a href="/Precio">Precios</a></li>
    <li><a href="#como-funciona">Cómo Funciona</a></li>
    <li><a href="//">Talentos Certificados</a></li>
  </ul>
          <ul>
              <div className="filters-container">
              <Select
                name="categories"
                options={categoryOptions}
                onChange={setSelectedCategory}
                isClearable
                placeholder="Especialidad"
                value={selectedCategory}
                styles={customStyles}
              />
              <Select
                name="tags"
                options={tagOptions}
                onChange={setSelectedTags}
                isMulti
                placeholder="Géneros"
                value={selectedTags}
                styles={customStyles}
              />
              <Select
                name="country"
                options={countryOptions}
                onChange={(option) => {
                  setSelectedCountry(option);
                  setSelectedCity(null); // Resetear la ciudad cuando se cambia el país
                }}
                isClearable
                placeholder="País"
                value={selectedCountry}
                styles={customStyles}
              />
              <Select
                name="city"
                options={cityOptions}
                onChange={setSelectedCity}
                isClearable
                placeholder="Ciudad"
                value={selectedCity}
                styles={customStyles}
                isDisabled={!selectedCountry} // Desactivar hasta que se seleccione un país
              />
            </div>          
          </ul>                   
        </nav>
      </aside>
     


      {/* Contenido Principal */}
      <main className="main-content">
        <div className="content-wrapper">
          <div className="column">                      
            <div className={isGridView ? 'artist-grid' : 'artist-list'}>
              {filteredArtists.map((artist, index) => (
                <div
                  className={`artist-card ${selectedArtist?.name === artist.name ? 'selected' : ''}`}
                  key={index}
                  onClick={() => goToArtistPage(artist.name)}
                  tabIndex={0}
                  role="button"
                  aria-label={`Ver perfil de ${artist.name}`}
                  onKeyPress={(e) => e.key === 'Enter' && goToArtistPage(artist.name)}
                >
                  <img src={artist.image} alt={artist.name} className="artist-image" />
                  <div className="artist-details">
                    <p1>{artist.name}</p1> 
                    <h4>{artist.location}</h4>
                    <h5>{artist.city}</h5>
                    <h3>{artist.categories?.slice(0, 5).join(', ')}</h3>
                    <h6>{artist.tags?.slice(0, 5).join(', ')}</h6>
                  </div>
                  <button
                    className="play-pause-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleArtistPlayPause(artist);
                    }}
                    aria-label={state.isPlaying && currentSongIndex === songs.findIndex(song => song.title === artist.playlist[0]?.title) ? 'Pausar' : 'Reproducir'}
                  >
                    {state.isPlaying && currentSongIndex === songs.findIndex(song => song.title === artist.playlist[0]?.title)
                      ? <FaPause />
                      : <FaPlay />}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      {selectedArtist && (
        <aside className={`sidebar-right ${isSidebarRightOpen ? 'open' : 'closed'}`}>
          <div className="artist-info">
            <button className="sidebar-right-toggle" onClick={() => setIsSidebarRightOpen(!isSidebarRightOpen)} aria-label="Toggle right sidebar">
              {isSidebarRightOpen ? <FaTimes /> : <FaBars />}
            </button>
            <div
              className={`artist-card artist-card-sidebar`}
              tabIndex={0}
              role="button"
              aria-label={`Ver perfil de ${selectedArtist.name}`}
              onClick={() => goToArtistPage(selectedArtist.name)}
              onKeyPress={(e) => e.key === 'Enter' && goToArtistPage(selectedArtist.name)}
            >
              <img src={selectedArtist.artistInfo?.profileImage || selectedArtist.image} alt={selectedArtist.name} className="artist-profile-image" />
              <h3>{selectedArtist.name}</h3>
              <p>{selectedArtist.artistInfo?.bio || "Sin biografía disponible"}</p>
              <p>País: {selectedArtist.location} / Ciudad: {selectedArtist.city}</p>
            </div>
          </div>
          <div className="artist-playlist">
            <h3>Playlist de {selectedArtist.name}</h3>
            <ul className="song-list">
              {selectedArtist.playlist.map((song, index) => (
                <li
                  className={`song-item ${currentSong.title === song.title ? 'playing' : ''}`}
                  key={index}
                  onClick={() => handleSongClick(songs.findIndex(s => s.title === song.title))}
                  tabIndex={0}
                  role="button"
                  aria-label={`Reproducir ${song.title} por ${song.artist}`}
                  onKeyPress={(e) => e.key === 'Enter' && handleSongClick(songs.findIndex(s => s.title === song.title))}
                >
                  <img src={song.image} alt={song.title} className="song-image" />
                  <div className="song-details">
                    <h4>{song.title}</h4>
                    <p>{song.artist}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      )}

      <footer className="footer">
        {currentSong.image && (
          <div className="song-info">
            <img src={currentSong.image} alt={currentSong.title} className="footer-song-image" />
            <div className="footer-song-details">
              <h4>{currentSong.title}</h4>
              <p>{currentSong.artist}</p>
            </div>
          </div>
        )}
        <AudioPlayer
          ref={audioPlayerRef}
          autoPlay={state.isPlaying}
          src={currentSong.src}
          onEnded={() => setCurrentSongIndex((currentSongIndex + 1) % filteredSongs.length)}
          showSkipControls={true}
          onClickPrevious={() => setCurrentSongIndex((currentSongIndex - 1 + filteredSongs.length) % filteredSongs.length)}
          onClickNext={() => setCurrentSongIndex((currentSongIndex + 1) % filteredSongs.length)}
          showJumpControls={false}
          customVolumeControls={[
            <input
              type="range"
              key="volume-control"
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={(e) => {
                const newVolume = parseFloat(e.target.value);
                setVolume(newVolume);
                const audio = document.querySelector('audio');
                if (audio) {
                  audio.volume = newVolume;
                }
              }}
              className="volume-slider"
              title="Control de Volumen"
              aria-label="Control de Volumen"
            />
          ]}
          onPlay={() => {
            dispatch({ type: 'PLAY' });
          }}
          onPause={() => {
            dispatch({ type: 'PAUSE' });
          }}
        />
      </footer>

      <nav className="mobile-nav">
        <button className="nav-button" aria-label="Filtros" onClick={toggleSidebarLeft}>
          <FaFilter />
        </button>
        <button className="nav-button" aria-label="Ver en cuadrícula" onClick={() => toggleView('grid')}>
          <FaTh />
        </button>
        <button className="nav-button" aria-label="Ver en lista" onClick={() => toggleView('list')}>
          <FaList />
        </button>
        <button className="nav-button" aria-label="Ver en mapa">
          <FaMapLocation />
        </button>
        <button className="nav-button" aria-label="PlayList" onClick={() => setIsSidebarRightOpen(!isSidebarRightOpen)}>
          <FaMusic />
        </button>
      </nav>
    </div>
  );
}

export default App;
